import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import { MdLogout } from "react-icons/md";
import url from "../../API";

export default function ImmigrationList() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const [mainlist, setMainlist] = React.useState([]);
  const [officer_map, setOfficer_map] = React.useState([]);
  const [visa_list, setVisa_list] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "immigrationofficerlist/getallimmigrationofficerlist",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setList(actualData.Visa_Information);
    } catch (err) {
      console.log(err);
    }
  };
  async function visa_info() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStora
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "visainformation/getallvisainformation",
        requestOptions
      );
      const response = await res.json();
      setVisa_list(response.Visa_Information);
      console.log(response.Visa_Information);
    } catch (err) {
      console.log(err);
    }
  }
  async function officer_map_list() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "visaofficermap/getallvisaofficermap",
        requestOptions
      );
      const response = await res.json();
      setOfficer_map(response.Visa_Officer_Map);
      console.log(response.Visa_Officer_Map);
    } catch (err) {
      console.log(err);
    }
  }
  const getOfficerInfoWithVisa = () => {
    return officer_map
      .map((officer) => {
        const officerInfo = list.find((info) => info.id === officer.officer_id);
        const visaMatch = visa_list.find((vs) => vs.id === officer.visa_id);
        if (officerInfo && visaMatch) {
          return {
            officerInfo,
            visa_id: officer.visa_id,
            visa_name: visaMatch.visa_name,
            map_id: officer.id,
          };
        }

        return null; // or any other value you prefer for undefined cases
      })
      .filter(Boolean); // filter out undefined values from the array
  };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const tableData = await table();
        const visaInfoData = await visa_info();
        const officerMapData = await officer_map_list();
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData(); // Call the fetchData function
  }, []);
  React.useEffect(() => {
    const officerInfoWithVisaArray = getOfficerInfoWithVisa();

    setMainlist(officerInfoWithVisaArray);
    console.log("combined array");
    console.log(officerInfoWithVisaArray);
  }, [list, officer_map, visa_list]);
  const handleDeleteOfficer = async (idx, office_map) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "immigrationofficerlist/deleteimmigrationofficerlist",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        axios
          .post(
            url + "visaofficermap/deletevisaofficermap",
            {
              id: office_map,
            },
            {
              headers: {
                /* Your headers here */
                "Content-Type": "application/json", // Example header
                Authorization: `Bearer ${token}`, // Example authorization header
              },
            }
          )
          .then((r) => {
            const fetchData = async () => {
              try {
                await Promise.all([table(), visa_info(), officer_map_list()]); // Wait for all API calls to finish
              } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors if necessary
              }
            };
            fetchData();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            // Handle error her
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Immigration Officers List
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) => {
                navigate("/immigration_form");
              }}
            >
              Create Immigration officer
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Officer Name",
              "Officer Photo",
              "Officer Position",
              "Officer Type",
              "Visa Name",
              "Action",
            ]}
            rows={[
              ...officer_map
                .filter(
                  (l) =>
                    l.officer_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    l.officer_position
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    l.visa_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    searchText.toLowerCase() === undefined
                )
                .map((vs, idx) => [
                  idx + 1,
                  vs.officer_name,
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={vs.officer_photo}
                    style={{
                      margin: "auto",
                    }}
                  />,

                  vs.officer_position,
                  vs.officer_type == 1
                    ? "Immigration"
                    : vs.officer_type == 2
                    ? "VisaRoot officer"
                    : "",
                  vs.visa_name,
                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleDeleteOfficer(vs.id, vs.id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        // localStorage.setItem("updateVisaId", vs.visa_id);
                        // localStorage.setItem("officerMapId", vs.id);
                        // localStorage.setItem("updateVisaName", vs.visa_name);

                        // localStorage.setItem(
                        //   "updateOffice",
                        //   JSON.stringify(vs)
                        // );
                        navigate("/immigration_form");
                        // navigate("/immigration_form", {
                        //   state: { updateOffice: vs },
                        // });
                      }}
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
