import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import ImageWithPopup from "../../components/ImagewithPop/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import DashboardHeader from "../../components/DashHeader/Index";
export default function Customer_testimonialList() {
  const classes = countryListStyles();

  const [list, setList] = React.useState([]);
  const [visalist, setVisaList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  const [countrylist, setcountrylist] = React.useState([]);
  const [testimonialMap, settestimonialMap] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deleteTestimonialId, setDeleteTestimonialId] = React.useState(null);
  const navigate = useNavigate();

  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "customertestimonial/getallcustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      const data = actualData.Customer_Testimonials.filter(
        (review) => review.visa_id === null
      );
      console.log(data);
      setList(data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    table();
    if (localStorage.getItem("createCust")) {
      toast.success("Created Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("createCust");
    }
    if (localStorage.getItem("updateCust")) {
      toast.success("Updated Sucessfully", {
        position: "top-center",
      });
      localStorage.removeItem("updateCust");
    }
  }, []);

  const handleDelete = (idx) => {
    setDeleteTestimonialId(idx);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: deleteTestimonialId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "customertestimonial/deletecustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status == 200) {
        toast.success("Deleted Sucessfully", {
          position: "top-center",
        });
        table();
      }
    } catch (err) {
      console.log(err);
    }
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>
              Customer Testimonial List
            </p>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) => {
                navigate("/customer_testimonial", {
                  state: { customer_data: null },
                });
              }}
            >
              Create customer testimonial
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Customer Name",
              "Customer Image",
              "Description",
              "Title",
              "Location",
              "Date",
              "Action",
            ]}
            rows={[
              ...list
                .filter((l) => {
                  const customer_name = l.customer_name?.toLowerCase() || "";
                  const title = l.title?.toLowerCase() || "";
                  const customer_location =
                    l.customer_location?.toLowerCase() || "";
                  const description =
                    l.description?.toLowerCase() ||
                    "";
                  const date = l.date?.toLowerCase() || "";
                  const searchLower = searchText.toLowerCase();
                  return (
                    customer_name.includes(searchLower) ||
                    title.includes(searchLower) ||
                    customer_location.includes(searchLower) ||
                    description.includes(searchLower) ||
                    date.includes(searchText) ||
                    searchLower === undefined
                  );
                })
                .map((vs, idx) => [
                  idx + 1,
                  vs.customer_name,
                  <ImageWithPopup
                    src={vs.customer_image}
                    alt="Example Image"
                  />,
                  vs.description.length > 25 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: vs.description.slice(0, 25) + "....",
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: vs.description,
                      }}
                    />
                  ),
                  
                  vs.title,
                  vs.customer_location,
                  vs.date,
                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleDelete(vs.id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        navigate("/customer_testimonial", {
                          state: { updateId: vs },
                        });
                      }}
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDelete}
      />
      <ToastContainer />
    </div>
  );
}
