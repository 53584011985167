import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { marginBottom: 10 },
  text: { fontSize: 12 },
  image: { width: 100, height: 100, marginBottom: 10 },
});

const Applicant = ({ applicant }) => {
  console.log(applicant);
  console.log("Back Passport Photo URL:", applicant.back_passport_photo); // Log the image URL

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.text}>Applicant {applicant.index + 1}</Text>
          <Text style={styles.text}>First Name: {applicant.first_name}</Text>
          <Text style={styles.text}>Last Name: {applicant.last_name}</Text>
          <Text style={styles.text}>Gender: {applicant.gender}</Text>
          <Text style={styles.text}>
            Date of Birth: {applicant.date_of_birth}
          </Text>
          <Text style={styles.text}>
            Passport Number: {applicant.passport_number}
          </Text>
          <Text style={styles.text}>
            Passport Valid Till: {applicant.passport_valid_till}
          </Text>
          <Text style={styles.text}>
            Passport Issued On: {applicant.passport_issued_on}
          </Text>
          <Text style={styles.text}>
            Address From Passport: {applicant.address_from_passport}
          </Text>

          <Text style={styles.text}>Visa Status: {applicant.visa_status}</Text>
          {applicant.visa_status === "Refused By Admin" ||
          applicant.visa_status === "Refused By Embassy" ? (
            <Text style={styles.text}>Cause: {applicant.cause}</Text>
          ) : null}
          {applicant.photo && (
            <>
              <Text style={styles.text}>Photo:</Text>
              <Image style={styles.image} src={applicant.photo} />
            </>
          )}
          {applicant.front_passport_photo && (
            <>
              <Text style={styles.text}>Passport Front Photo:</Text>
              <Image
                style={styles.image}
                src={applicant.front_passport_photo}
              />
            </>
          )}
          {applicant.back_passport_photo ? (
            <>
              <Text style={styles.text}>Passport Back Photo:</Text>
              <Image style={styles.image} src={applicant.back_passport_photo} />
            </>
          ) : (
            <Text style={styles.text}>No Passport Back Photo provided.</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default Applicant;
