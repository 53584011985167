import React from "react";
import { AiFillFlag, AiFillHome } from "react-icons/ai";
import {
  FaDashcube,
  FaPoll,
  FaPortrait,
  FaScroll,
  FaPassport,
  FaTag,
  FaBalanceScale,
  FaBook,
  FaDoorOpen,
  FaUserShield,
  FaComments,
  FaCreditCard,
  FaQuestion,
  FaHourglassHalf,
  FaFolderOpen,
  FaQuestionCircle,
  FaNewspaper,
  FaDollarSign,
  FaFileSignature,
  FaList,
  FaBlog,
  FaSearch,
  FaTicketAlt,
  FaBriefcase,
  FaFile,
  FaCog,FaUserTie,
  FaAddressBook,
} from "react-icons/fa";

// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    value: 0,
    name: "dashboard",
    title: <>Dashboard</>,
    path: "/dashboard",
    icon: <AiFillHome className="sidebar-icon" />,
  },
  // {
  //   value: 1,
  //   name: "newOffers",
  //   title: <>Country</>,
  //   path: "/country",
  //   icon: <AiFillFlag className="sidebar-icon" />,
  // },
  {
    value: 2,
    name: "country",
    title: <>Country List</>,
    path: "/country_list2",
    icon: <AiFillFlag className="sidebar-icon" />,
  },
  {
    value: 3,
    name: "countryList",
    title: <>Visa Category</>,
    path: "/visa_time",
    icon: <FaPassport className="sidebar-icon" />,
  },
  // {
  //   value: 4,
  //   name: "visaCategory",
  //   title: <>Visa Information</>,
  //   path: "/information_form",
  //   icon: <RiVisaFill className="sidebar-icon" />,
  // },
  {
    value: 5,
    name: "visaCategory",
    title: <>Visa Information List</>,
    path: "/information_list",
    icon: <FaBook className="sidebar-icon" />,
  },
  {
    value: 6,
    name: "visaPricing",
    title: <>Entry Type</>,
    path: "/entry",
    icon: <FaDoorOpen className="sidebar-icon" />,
  },

  // {
  //   value: 7,
  //   name: "visaApplyingType",
  //   title: <>Add Immigration Officer</>,
  //   path: "/immigration_form",
  //   icon: <FaUserFriends className="sidebar-icon" />,
  // },
  // {
  //   value: 8,
  //   name: "calculatorTool",
  //   title: <>Immigration Officer list</>,
  //   path: "/immigration_list",
  //   icon: <FaUserShield className="sidebar-icon" />,
  // },
  // {
  //   value: 9,
  //   name: "newOffers",
  //   title: <>Partners We Work With</>,
  //   path: "/partners",
  //   icon: <FaUserFriends className="sidebar-icon" />,
  // },
  // {
  //   value: 10,
  //   name: "visaApplications",
  //   title: <>FAQ For Visa</>,
  //   path: "/faq_visa",
  //   icon: <FaWpforms className="sidebar-icon" />,
  // },
  // {
  //   value: 11,
  //   name: "newOffers",
  //   title: <>Customer Testimonials</>,
  //   path: "/testimonial",
  //   icon: <FaUserFriends className="sidebar-icon" />,
  // },
  {
    value: 12,
    name: "newOffers",
    title: <>Testimonial List</>,
    path: "/customer_testimoniallist",
    icon: <FaComments className="sidebar-icon" />,
  },
  {
    value: 12,
    name: "newOffers",
    title: <>Discount Type</>,
    path: "/discount",
    icon: <FaTag className="sidebar-icon" />,
    //icon: <FaUserFriends className="sidebar-icon" />,
  },

  // {
  //   value: 14,
  //   name: "newOffers",
  //   title: <>Visa Question List</>,
  //   path: "/question_list",
  //   icon: <FaQuestionCircle className="sidebar-icon" />,
  // },
  // {
  //   value: 15,
  //   name: "newOffers",
  //   title: <>Visa For Country</>,
  //   path: "/for_which_country",
  //   icon: <AiFillFlag className="sidebar-icon" />,
  // },
  {
    value: 16,
    name: "newOffers",
    title: <>Payment Gateway</>,
    path: "/payment_gateway",
    icon: <FaCreditCard className="sidebar-icon" />,
  },
  // {
  //   value: 17,
  //   name: "newOffers",
  //   title: <>FAQ For Country</>,
  //   path: "/faq_for_country",
  //   icon: <FaQuestion className="sidebar-icon" />,
  // },
  {
    value: 18,
    name: "newOffers",
    title: <>Application Status</>,
    path: "/application_status",
    icon: <FaHourglassHalf className="sidebar-icon" />,
  },
  // {
  //   value: 19,
  //   name: "newOffers",
  //   title: <>Documents Required</>,
  //   path: "/docs_required",
  //   icon: <FaFolderOpen className="sidebar-icon" />,
  // },
  {
    value: 18,
    name: "newOffers",
    title: <>FAQ</>,
    path: "/faq",
    icon: <FaQuestionCircle className="sidebar-icon" />,
  },
  {
    value: 19,
    name: "newOffers",
    title: <>Fee Types</>,
    path: "/fee_type",
    icon: <FaDollarSign className="sidebar-icon" />,
  },
  // {
  //   value: 20,
  //   name: "newOffers",
  //   title: <>Visa Fee</>,
  //   path: "/Visa_fee",
  //   icon: <FaCcVisa className="sidebar-icon" />,
  // },
  // {
  //   value: 21,
  //   name: "newOffers",
  //   title: <>Visa Price Compare</>,
  //   path: "/pricing_cmp",
  //   icon: <FaBalanceScale className="sidebar-icon" />,
  // },
  {
    value: 22,
    name: "newOffers",
    title: <>News Letter</>,
    path: "/news_letter",
    icon: <FaNewspaper className="sidebar-icon" />,
  },
  {
    value: 22,
    name: "newOffers",
    title: <>Send Mail</>,
    path: "/send_mail",
    icon: <FaNewspaper className="sidebar-icon" />,
  },
  {
    value: 23,
    name: "newOffers",
    title: <>User List</>,
    path: "/user_list",
    icon: <FaFile className="sidebar-icon" />,
  },
  {
    value: 23,
    name: "newOffers",
    title: <>Visa Applications</>,
    path: "/application_list",
    icon: <FaFile className="sidebar-icon" />,
  },
  {
    value: 24,
    name: "coupon",
    title: <>Coupon</>,
    path: "/coupon",
    icon: <FaTicketAlt className="sidebar-icon" />,
  },
  {
    value: 25,
    name: "coupon",
    title: <>SEO</>,
    path: "/seo",
    icon: <FaSearch className="sidebar-icon" />,
  },
  {
    value: 26,
    name: "coupon",
    title: <>Blog section</>,
    path: "/bloglist",
    icon: <FaBlog className="sidebar-icon" />,
  },
  {
    value: 27,
    name: "coupon",
    title: <>Blog Category</>,
    path: "/blog_category",
    icon: <FaList className="sidebar-icon" />,
  },
  {
    value: 28,
    name: "coupon",
    title: <>Cover Letter List</>,
    path: "/cover_letter_list",
    icon: <FaFileSignature className="sidebar-icon" />,
  },
  {
    value: 29,
    name: "setting",
    title: <>Settings</>,
    path: "/setting",
    icon: <FaCog className="sidebar-icon" />,
  },
  {
    value: 30,
    name: "Contact user list",
    title: <>User Contact List</>,
    path: "/contact_list",
    icon: <FaAddressBook className="sidebar-icon" />,
  },
  {
    value: 31,
    name: "Careers Page",
    title: <>Careers Page</>,
    path: "/careers",
    icon: <FaBriefcase className="sidebar-icon" />,
  },
  {
    value: 32,
    name: "InvestorsList",
    title: <>Investors List</>,
    path: "/investors",
    icon: <FaUserTie className="sidebar-icon" />,
  },
];

export const bottomNavigationConfig = [
  {
    value: 0,
    name: "home",
    title: <>Home</>,
    path: "/dashboard",
    icon: <AiFillHome className="sidebar-icon" />,
  },
  {
    value: 1,
    name: "dashboard",
    title: <>Dashboard</>,
    path: "/dashboard",
    icon: <FaDashcube className="sidebar-icon" />,
  },
  {
    value: 2,
    name: "profile",
    title: (
      <>
        Profile <span className="alert-helper-ylw">Imp</span>
      </>
    ),
    path: "/profile",
    icon: <FaPortrait className="sidebar-icon" />,
  },
  {
    value: 3,
    name: "jobfeed",
    title: (
      <>
        JobFeed <span className="alert-helper">New</span>
      </>
    ),
    path: "/jobfeed",
    icon: <FaScroll className="sidebar-icon" />,
  },
  {
    value: 4,
    name: "resources",
    title: <>Resources</>,
    path: "/resource",
    icon: <FaPoll className="sidebar-icon" />,
  },
];
export default sidebarConfig;
