import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
import ImageWithPopup from "../../components/ImagewithPop/Index";
import DashboardHeader from "../../components/DashHeader/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
export default function BlogCategory() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [logoPhotos, setlogoPhotos] = React.useState([]);
  const [logoPrevPics, setlogoPics] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deleteblogCatId, setDeleteblogCatId] = React.useState(null);
  const formData = new FormData();
  const [state, setState] = React.useState({
    category_name: "",
    searchText: "",
    category_image: "",
    isUpdate: false,
    id: null,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [partnerList, setPartnerList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);

  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "blogcategory/getallblogcategory",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaList(actualData.BlogCategory);
    } catch (e) {
      console.log(e);
    }
  }
  // async function table2() {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }

  //     const requestOptions = {
  //       method: "GET",
  //       headers: loginHeaders,
  //     };
  //     const res = await fetch(
  //       url +"partnerweworkwith/getallpartnerweworkwith",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     setPartnerList(actualData.Partners_We_Work_With);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  // const getVisaName = () => {
  //   return visaList
  //     .map((vs) => {
  //       const plInfo = partnerList.find((pl) => pl.visa_id == vs.id);

  //       if (plInfo) {
  //         return {
  //           plInfo,
  //           visa_name: vs.visa_name,
  //         };
  //       }
  //       return null; // or any other value you prefer for undefined cases
  //     })
  //     .filter(Boolean); // filter out undefined values from the array
  // };

  React.useEffect(() => {
    table();
  }, []);

  const handleDelete = (idx) => {
    setDeleteblogCatId(idx);
    setConfirmDialogOpen(true);
  };

  const handleSaveVisaCategory = () => {
    axios
      .post(
        url + "blogcategory/createblogcategory",
        {
          category_name: state.category_name,
          category_image: state.category_image,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 201) {
          toast.success("Created Sucessfully", {
            position: "top-center",
          });
          table();
          setState({
            category_name: "",
            searchText: "",
            category_image: "",
            isUpdate: false,
          });
          window.scrollTo({ top: 300, behavior: "smooth" });
        } else {
          toast.error(r.data.message, {
            position: "top-center",
          });
        }

        // table2();
        // localStorage.removeItem("logo");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleVisaCategoryDelete = () => {
    axios
      .post(
        url + "blogcategory/deleteblogcategory",
        {
          id: deleteblogCatId,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Deleted Sucessfully", {
            position: "top-center",
          });
        }
        //table2();
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        url + "blogcategory/updateblogcategory",
        {
          id: state.id,
          category_name: state.category_name,
          category_image: state.category_image,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        console.log(r);
        if (r.data.status == 200) {
          toast.success("Updated Sucessfully", {
            position: "top-center",
          });
          window.scrollTo({ top: 300, behavior: "smooth" });
        }
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      category_name: "",
      searchText: "",
      category_image: "",
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>Blog Category</p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          {/* <p className={classes.title}>Blog Category Information</p> */}
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  type="Category Name"
                  label="Category Name"
                  fullWidth
                  value={state.category_name}
                  // onChange={(e) =>
                  //   setState({
                  //     ...state,
                  //     category_name: e.target.value,
                  //   })
                  // }
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove non-alphabetic characters
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    // Ensure input does not exceed 50 characters
                    const trimmedInput = validInput.slice(0, 50);
                    setState({
                      ...state,
                      category_name: trimmedInput,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <CustomInputField
                  label="Category Image"
                  type="File"
                  fullWidth
                  onChange={(e) => {
                    const imageRef = ref(storage, `/images+${Math.random()}`);
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          // localStorage.setItem("cat_img", url);

                          setState({
                            ...state,
                            category_image: url,
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {state.category_image && (
                  <>
                    <p>Category Image</p>{" "}
                    {/* <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.category_image}
                      style={{
                        margin: "auto",
                      }}
                    /> */}
                    <ImageWithPopup
                      src={state.category_image}
                      alt="Example Image"
                    />
                  </>
                )}
              </Grid>
              {/* <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaList.map((item) => item.visa_name)} // Set options from entry type names
                  value={state.visa_name}
                  label="Visa names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryTypeName = v;

                    const selectedEntryType = visaList.find(
                      (item) => item.visa_name === selectedEntryTypeName
                    );

                    setState((prevState) => ({
                      ...prevState,
                      visa_name: v,
                      visa_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                  // Handle onChange event
                />
              </Grid> */}

              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.category_name && !!state.category_image) {
                        handleUpdateVisaCategory(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleUpdateVisaCategory(state.id);
                    // }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.category_name && !!state.category_image) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                    // onClick={(e) => {
                    //   handleSaveVisaCategory();
                    // }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Blog Category Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Category Name", "Category Image", "Action"]}
              rows={[
                ...visaList
                  .sort()
                  .filter((l) => {
                    const catName = l.category_name?.toLowerCase() || "";
                    const searchLower = (state.searchText || "").toLowerCase();
                    return (
                      catName.includes(searchLower) || searchLower === undefined
                    );
                  })
                  .map((pl, idx) => [
                    idx + 1,
                    pl.category_name,
                    <ImageWithPopup
                      src={pl.category_image}
                      alt="Example Image"
                    />,
                    // <Avatar
                    //   variant="rounded"
                    //   alt="VISA"
                    //   src={}
                    //   style={{
                    //     margin: "auto",
                    //   }}
                    // />,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleDelete(pl.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState({
                            category_name: pl.category_name,
                            searchText: "",
                            category_image: pl.category_image,
                            isUpdate: true,
                            id: pl.id,
                          });
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
