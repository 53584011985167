import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
// import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
// import CustomPill from "../../components/CustomPill/Index";
// import CustomTable from "../../components/CustomTable/Index";
// import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import url from "../../API";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
export default function Docs_required() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { visaId } = location.state || {};
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [state, setState] = React.useState({
    visa_name: "",
    visa_id: "",
    docs_icon: "",
    docs_title: "",
    isUpdate: false,
    searchText: "",
    id: null,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [visaName, setVisaName] = React.useState("");
  const [mainlist, setMainList] = React.useState([]);
  const authToken = localStorage.getItem("tkn");
  const [deletepriceId, setDeletepriceId] = React.useState(null);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { visa_id: visaId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "visa/documentrequireddetailsbyvisaid",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaList(actualData.Document_details);
      setState((prevState) => ({
        ...prevState,
        visa_id: visaId,
        visa_name: actualData.Visa_name,
      }));
      setVisaName(actualData.Visa_name);
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    table();
  }, []);

  const handleSaveVisaCategory = () => {
    if (!!state.docs_icon && !!state.docs_title && !!state.visa_id) {
      axios
        .post(
          url + "documentrequired/createdocumentrequired",
          {
            visa_id: state.visa_id,
            document_icon: state.docs_icon,
            document_name: state.docs_title,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((r) => {
          if (r.data.status == 200) {
            toast.success("Created Sucessfully", {
              position: "top-center",
            });
            setState((prevState) => ({
              ...prevState,
              visa_name: visaName,
              visa_id: state.visa_id,
              docs_icon: "",
              docs_title: "",
              isUpdate: false,
              id: null,
            }));

            window.scrollTo({ top: 300, behavior: "smooth" });
            table();
          } else {
            toast.error(r.data.message, {
              position: "top-center",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    }
  };

  const handleVisaCategoryDelete = () => {
    axios
      .post(
        url + "documentrequired/deletedocumentrequired",
        {
          id: deletepriceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Deleted Sucessfully", {
            position: "top-center",
          });

          table();
          setConfirmDialogOpen(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleDelete = (idx) => {
    setDeletepriceId(idx);
    setConfirmDialogOpen(true);
  };
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  const handleUpdateVisaCategory = (idx) => {
    if (!!state.docs_icon && !!state.docs_title && !!state.visa_id) {
      axios
        .post(
          url + "documentrequired/updatedocumentrequired",
          {
            id: idx,
            visa_id: state.visa_id,
            document_icon: state.docs_icon,
            document_name: state.docs_title,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((r) => {
          if (r.data.status == 200) {
            toast.success("Updated Sucessfully", {
              position: "top-center",
            });
            setState((prevState) => ({
              ...prevState,
              visa_name: visaName,
              visa_id: state.visa_id,
              docs_icon: "",
              docs_title: "",
              isUpdate: false,
              id: null,
            }));
            window.scrollTo({ top: 300, behavior: "smooth" });
            table();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    }
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Documents Required
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Documents</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaList.map((item) => item.visa_name)}
                  value={state.visa_name}
                  label="Visa Names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = visaList.find(
                      (item) => item.visa_name === v
                    );
                    setState((prevState) => ({
                      ...prevState,
                      visa_name: v,
                      visa_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="file"
                  fullWidth
                  label="Document Icon"
                  onChange={(e) => {
                    const imageRef = ref(
                      storage,
                      `/thumbnail+${Math.random()}`
                    );
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          setState((prevState) => ({
                            ...prevState,
                            docs_icon: url,
                          }));
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                {state.docs_icon && (
                  <>
                    <p>Icon</p>
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.docs_icon}
                      style={{
                        margin: "auto",
                      }}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Document Name"
                  fullWidth
                  value={state.docs_title}
                  name="name"
                  // onChange={(e) => {
                  //   setState((prevState) => ({
                  //     ...prevState,
                  //     docs_title: e.target.value,
                  //   }));
                  // }}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove non-alphabetic characters
                    // const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    // Ensure input does not exceed 50 characters
                    const trimmedInput = input.slice(0, 50);
                    setState({
                      ...state,
                      docs_title: trimmedInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateVisaCategory(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"Create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveVisaCategory();
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          <p className={classes.title}>Documents Required Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  searchText: e.target.value,
                }))
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Visa", "Icon", "Document Name", "Action"]}
              rows={[
                ...visaList
                  .filter((l) => {
                    const visa_name = l.visa_name?.toLowerCase() || "";
                    const document_name = l.document_name?.toLowerCase() || "";

                    const searchLower = (state.searchText || "").toLowerCase();
                    return (
                      visa_name.includes(searchLower) ||
                      document_name.includes(searchLower) ||
                      searchLower === undefined
                    );
                  })
                  .map((pl, idx) => [
                    idx + 1,
                    state.visa_name,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={pl.document_icon}
                      style={{
                        margin: "auto",
                      }}
                    />,
                    pl.document_name,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleDelete(pl.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState((prevState) => ({
                            ...prevState,
                            docs_icon: pl.document_icon,
                            docs_title: pl.document_name,
                            isUpdate: true,
                            id: pl.id,
                          }));
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
          <ToastContainer />
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
