import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import CustomMenuButton from "../../components/CustomMenuButton/Index";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
export default function Application_list() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");

  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "applicationform/getallapplicationform",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      const filteredApplications = actualData.Application_Form.filter(
        (app) => app.application_details_count > 0
      );
      setList(filteredApplications);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
  }, []);
  const handleStatusUpdate = (menuinfo, status) => {
    const token = localStorage.getItem("tkn");
    axios
      .post(
        url + "applicationform/updateapplicationform",
        {
          id: menuinfo.id,
          unique_id: menuinfo.unique_id,
          user_id: menuinfo.user_id,
          visa_id: menuinfo.visa_id,
          visa_status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        table();
        // console.log("Status updated successfully:", response);
        // Handle successful update (e.g., refresh list, show notification, etc.)
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        // Handle error
      });
  };

  const handleDelete = (idx) => {
    axios
      .post(
        url + "applicationdetails/deleteapplicationdetails",
        {
          id: idx,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>
              Application List
            </p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "User Name",
              "Visa Applied For",
              // "Payment Status",
              "Payment Type",

              "No. Of Applicant",
              "Applied Date",
              "Receiving Date",
              "Action",
            ]}
            rows={[
              ...list
                .filter((l) => {
                  // Define the properties to check
                  const propertiesToCheck = [
                    l.admin_user_first_name,
                    l.visa_name,
                    l.date_of_arrival,
                  ];

                  // Check if any property includes the searchText, only if it's not null or undefined
                  return (
                    propertiesToCheck.some(
                      (prop) =>
                        prop != null &&
                        prop.toLowerCase().includes(searchText.toLowerCase())
                    ) || searchText === undefined
                  );
                })
                .map((vs, idx) => [
                  idx + 1,
                  vs.admin_user_first_name == null ||
                  vs.admin_user_first_name == ""
                    ? "--"
                    : vs.admin_user_first_name,
                  vs.visa_name,
                  // vs.payment_status,
                  vs.payment_details.length > 0 &&
                  vs.payment_details[0].payment_type != null
                    ? vs.payment_details[0].payment_type
                    : "--",
                  vs.application_details_count,
                  vs.created_date.slice(0, 10),
                  vs.visa_receive_date == null || vs.visa_receive_date == ""
                    ? "--"
                    : vs.visa_receive_date,
                  <span className={classes.actionBtn}>
                    {/* <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleDelete(vs.id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      text={"View"}
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        localStorage.setItem("applicationId", vs.id);
                        navigate("/application_details");
                      }}
                    /> */}
                    <CustomMenuButton
                      menuinfo={vs}
                      onStatusUpdate={handleStatusUpdate}
                      table={table}
                    />
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
