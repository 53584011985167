import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router";
import { FaEllipsisV } from "react-icons/fa";
import url from "../../API";
import ConfirmationDialog from "../ConfirmationDialog/Index"; // Adjust the import path as necessary

const MenuButton = ({ menuinfo, table }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleView = () => {
    navigate("/information_form", { state: { updateId: menuinfo.id } });
    handleClose();
  };

  const handleDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: menuinfo.id };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "visainformation/deletevisainformation",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      await table(); // Call the fetchData function from the parent component
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    handleDelete();
    handleConfirmDialogClose();
  };

  const handleFaqClick = () => {
    navigate("/faq_visa", { state: { visaId: menuinfo.id } });
    handleClose();
  };
  const handleCouponClick = () => {
    navigate("/applicable_coupon", { state: { visaId: menuinfo.id,visaName:menuinfo.visa_name } });
    handleClose();
  };

  const handlePartnerClick = () => {
    navigate("/partners", { state: { visaId: menuinfo.id } });
    handleClose();
  };

  const handleCustomerClick = () => {
    navigate("/testimonial", { state: { visaId: menuinfo.id } });
    handleClose();
  };

  const handleDocsClick = () => {
    navigate("/docs_required", { state: { visaId: menuinfo.id } });
    handleClose();
  };

  const handleImmigrationClick = () => {
    navigate("/immigration_form", { state: { visaId: menuinfo.id } });
    handleClose();
  };

  const handleQuestionListClick = () => {
    navigate("/question_list", { state: { visaId: menuinfo.id } });
    handleClose();
  };

  const handlePriceCmpListClick = () => {
    navigate("/pricing_cmp", { state: { visaId: menuinfo.id } });
    handleClose();
  };

  return (
    <>
      <FaEllipsisV
        size={20}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>Update</MenuItem>
        <MenuItem onClick={handleConfirmDialogOpen}>Remove</MenuItem>
        <MenuItem onClick={handleCouponClick}>Applicable Coupons</MenuItem>
        <MenuItem onClick={handleFaqClick}>Faq Section</MenuItem>
        <MenuItem onClick={handlePartnerClick}>
          Partner We Work With Section
        </MenuItem>
        <MenuItem onClick={handleCustomerClick}>
          Customer Testimonial Section
        </MenuItem>
        <MenuItem onClick={handleDocsClick}>Document Section</MenuItem>
        <MenuItem onClick={handleImmigrationClick}>
          Immigration Officer Section
        </MenuItem>
        <MenuItem onClick={handleQuestionListClick}>
          Additional Question List
        </MenuItem>
        <MenuItem onClick={handlePriceCmpListClick}>
          Price Compare Section
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default MenuButton;
