import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { CiMail } from "react-icons/ci";
import NotificationBtn from "../../components/NotificationBtn/Index";
import { useNavigate } from "react-router-dom";
const DashboardHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    handleClose();
    navigate("/");
  };

  const handleDashboard = () => {
    navigate("/dashboard");
    handleClose();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20
        }}
      >
        <div>
          {/* Placeholder for search bar or any other element */}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
          <div
            style={{
              backgroundColor: "#ececec",
              paddingInline: "8px",
              paddingTop: "8px",
              borderRadius: "50%"
            }}
          >
            <NotificationBtn />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              cursor: "pointer"
            }}
            onClick={handleClick}
          >
            <img
              src="https://th.bing.com/th/id/OIP.JkiKVe6uSDhUMZY_BgVK0QHaHa?w=500&h=500&rs=1&pid=ImgDetMain"
              style={{ borderRadius: "50%", width: "28px", height: "28px" }}
            />
            <p style={{ fontSize: "12px" }}>Howdy, Admin</p>
          </div>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        style={{
          marginTop: "10px"
        }}
      >
        {/* <MenuItem onClick={handleLogout}>Logout</MenuItem>  */}
        <div
          style={{
            paddingInline: "24px"
          }}
        >
          <p
            onClick={handleDashboard}
            style={{ marginBlock: "8px", cursor: "pointer" }}
          >
            DashBoard
          </p>
          <p onClick={handleLogout} style={{ cursor: "pointer" }}>
            Log Out
          </p>
        </div>
      </Menu>
    </div>
  );
};

export default DashboardHeader;
