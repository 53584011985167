import { Box } from "@mui/material";
import { styled } from "@mui/system";

const ScrollableBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  height: "100%",
  "&::-webkit-scrollbar": {
    width: "8px", // Adjust the width here
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&": {
    scrollbarWidth: "thin",
    scrollbarColor: "#888 #f1f1f1",
  },
}));

export default ScrollableBox;
