import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import DashboardHeader from "../../components/DashHeader/Index";
export default function VisaApplicationStatus() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    comment: "",
    status: "",
    comment_name: "",
    searchText: "",
    isUpdate: false
  });
  const [visaList, setVisaList] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletestatusId, setDeletestatusId] = React.useState(null);
  function table() {
    axios
      .get(url + "visa_applicationstatus/getallvisa_applicationstatus", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        // Handle the response
        setVisaList(response.data.Visa_Application_Status);
        console.log(response.data.Visa_Application_Status);
      })
      .catch(error => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  React.useEffect(() => {
    table();
  }, []);
  const handleDelete = idx => {
    setDeletestatusId(idx);
    setConfirmDialogOpen(true);
  };

  const handleSaveVisaCategory = () => {
    const { status, comment_name } = state;

    // Validation to check for non-empty fields
    if (!status || !comment_name) {
      toast.error("Please fill in all required fields.");
      return;
    }

    axios
      .post(
        url + "visa_applicationstatus/createvisa_applicationstatus",
        {
          status_name: state.status,
          is_comment_available: state.comment_name === "Yes" ? true : false
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(r => {
        toast.success("Created Sucessfully", {
          position: "top-center"
        });
        table();
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });

    setState({
      comment: "",
      status: "",
      comment_name: ""
    });
  };

  const handleVisaCategoryDelete = () => {
    axios
      .post(
        url + "visa_applicationstatus/deletevisa_applicationstatus",
        {
          id: deletestatusId
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(r => {
        toast.success("Deleted Sucessfully", {
          position: "top-center"
        });
        table();
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
    setConfirmDialogOpen(false);
  };
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleUpdateVisaCategory = idx => {
    const { status, comment_name } = state;

    // Validation to check for non-empty fields
    if (!status || !comment_name) {
      toast.error("Please fill in all required fields.");
      return;
    }

    axios
      .post(
        url + "visa_applicationstatus/updatevisa_applicationstatus",
        {
          id: idx,
          status_name: state.status,
          is_comment_available: state.comment_name === "Yes" ? true : false
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(r => {
        toast.success("Updated Sucessfully", {
          position: "top-center"
        });
        table();
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });

    setState({
      comment: "",
      status: "",
      comment_name: "",
      isUpdate: false
    });
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px"
            }}
          >
            {" "}<p style={{ marginTop: "2px", fontSize: "22px" }}>
              Visa Application Status
            </p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          {/* <p className={classes.title}>Application Status</p> */}
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputField
                  label="Status"
                  fullWidth
                  value={state.status}
                  onChange={e => {
                    const input = e.target.value;
                    // Remove non-alphabetic characters
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    // Ensure input does not exceed 50 characters
                    const trimmedInput = validInput.slice(0, 70);
                    setState({
                      ...state,
                      status: trimmedInput
                    });
                  }}
                  // onChange={(e) =>
                  //   setState({
                  //     ...state,
                  //     status: e.target.value,
                  //   })
                  // }
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={["Yes", "No"]}
                  value={state.comment_name}
                  changeCallBack={(e, v) => {
                    setState({
                      ...state,
                      comment_name: v
                    });
                  }}
                  label="Is Comment Available"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} alignItems="end">
                {state.isUpdate
                  ? <CustomButton
                      text={"Update"}
                      variant="contained"
                      color="warning"
                      fullWidth
                      onClick={e => {
                        if (!!state.status) {
                          handleUpdateVisaCategory(state.id);
                        }
                      }}
                    />
                  : <CustomButton
                      text={"create"}
                      variant="contained"
                      fullWidth
                      onClick={e => {
                        if (!!state.status) {
                          handleSaveVisaCategory();
                        }
                      }}
                    />}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Application Status List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={e =>
                setState({
                  ...state,
                  searchText: e.target.value
                })}
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Status", "Is Comment Available", "Action"]}
              rows={[
                ...visaList
                  .filter(
                    l =>
                      l.status_name
                        .toLowerCase()
                        .includes((state.searchText || "").toLowerCase()) ||
                      (state.searchText || "").toLowerCase() === undefined
                  )
                  .map((vs, idx) => [
                    idx + 1,
                    vs.status_name,
                    vs.is_comment_available == true ? "Yes" : "No",
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={e => handleDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={e => {
                          const c_name =
                            vs.is_comment_available == true ? "Yes" : "No";
                          setState({
                            status: vs.status_name,
                            comment: vs.is_comment_available,
                            comment_name: c_name,
                            isUpdate: true,
                            id: vs.id
                          });
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>
                  ])
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
