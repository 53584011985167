import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useLocation, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import DashboardHeader from "../../components/DashHeader/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
export default function Applicable_coupon() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    discount_type_name: "",
    visa_name: "",
    visa_name_id: "",
    coupon:[],
    coupon_id:[],
    searchText: "",
    isUpdate: false,
  });
  const location = useLocation();
  const { visaId,visaName } = location.state || {};
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletediscount_typeId, setDeletediscount_typeId] =
    React.useState(null);
  const [visaList, setVisaList] = React.useState([]);
  const [visainfoList, setVisainfoList] = React.useState([]);
  const [couponlist, setcouponlist] = React.useState([]);
  function table() {
    axios
      .get(url + "visacouponmap/getallvisacouponmap", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setVisaList(response.data.VisaCouponMap);
        console.log(response.data.VisaCouponMap);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  function infotable() {
    axios
      .get(url + "visainformation/getallvisainformation", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setVisainfoList(response.data.Visa_Information);
        console.log(response.data.Visa_Information);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  async function coupontable() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "coupon/getallcoupon", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setcouponlist(actualData.Coupon);
    } catch (e) {
      console.log(e);
    }
  }
  React.useEffect(() => {
    table();
    infotable();
    coupontable();
  }, []);
  console.log(visaId,"hhh",visaName);
  
  const handleDelete = (idx) => {
    setDeletediscount_typeId(idx);
    setConfirmDialogOpen(true);
  };
  const handleSaveVisaCategory = () => {
    axios
      .post(
        url + "visacouponmap/createvisacouponmap",
        {
          visa_id: visaId,
          coupon_ids:state.coupon_id,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Created Sucessfully", {
            position: "top-center",
          });
          table();
          
        }else{
          toast.error(r.data.message, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-center",
        });
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      coupon:[],
      coupon_id:[],
    });
  };
  const handleVisaCategoryDelete = () => {
    axios
      .post(
        url + "visacouponmap/deletevisacouponmap",
        {
          id: deletediscount_typeId,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Deleted Sucessfully", {
            position: "top-center",
          });
          table();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setConfirmDialogOpen(false);
  };
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        url + "discounttype/updatediscounttype",
        {
          id: idx,
          discount_type_name: state.discount_type_name,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Updated Sucessfully", {
            position: "top-center",
          });
          // table();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      discount_type_name: "",
      isUpdate: false,
    });
  };
  console.log(state);
  
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>Applicable Coupons on {visaName} </p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              
              <Grid item xs={12}>
                <CustomInputAndSelectField
                  multiple // Enable multiple selection
                  options={couponlist.map((item) => item.coupon_code)}
                  value={state.coupon} // Value should be an array for multiple selection
                  label="Select Coupon Code"
                 
                  fullWidth
                  changeCallBack={(e, values) => {
                    const selectedCountries = values.map((v) => {
                      const selectedEntryType = couponlist.find(
                        (item) => item.coupon_code === v
                      );
                      return {
                        name: v,
                        id: selectedEntryType ? selectedEntryType.id : "",
                      };
                    });
                    setState((prevState) => ({
                      ...prevState,
                      coupon: values, // Update the array of selected countries
                      coupon_id: selectedCountries.map((item) => item.id),
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.discount_type_name) {
                        handleUpdateVisaCategory(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"apply"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.coupon) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>Applied Coupons List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
  fullWidth
  cols={["Sl No.", "Visa Name", "Coupons", "Action"]}
  rows={[
    ...visaList
      .filter((vs) => vs.visa_id === visaId) // Filter the visaList based on the visa_id
      .map((vs, idx) => [
        idx + 1,
        visaName, // Assuming visaName is coming from somewhere else in your code
        vs.coupons[0]?.name || "No Coupon", // Safeguard in case there is no coupon
        <span className={classes.actionBtn}>
          <CustomButton
            variant="contained"
            color="error"
            onClick={() => handleDelete(vs.id)} // Pass the visa id to the delete handler
            icon={<FaTimes />}
          />
        </span>,
      ]),
  ]}
/>

          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
