import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import url from "../../API";
export default function Visa_fee() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    visa_name: "",
    visa_id: "",
    fee_name: "",
    feetype_id: "",
    price: "",
    searchText: "",
    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [mapList, setMapList] = React.useState([]);
  const [feelist, setfeelist] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        url + "visainformation/getallvisainformation",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaList(actualData.Visa_Information);
    } catch (e) {
      console.log(e);
    }
  }
  async function table2() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "feetypes/getallfeetypes", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setfeelist(actualData.Fee_Types);
    } catch (e) {
      console.log(e);
    }
  }
  async function table3() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "visafees/getallvisafees", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setMapList(actualData.Visa_Fees);
    } catch (e) {
      console.log(e);
    }
  }
  const getAllName = () => {
    return mapList
      .map((ml) => {
        const vsInfo = visaList.find((pl) => pl.id == ml.visa_id);
        const countryinfo = feelist.find((cl) => cl.id == ml.feetype_id);
        if (countryinfo && vsInfo) {
          return {
            visa_id: ml.visa_id,
            feetype_id: ml.feetype_id,
            fee_name: countryinfo.fee_name,
            visa_name: vsInfo.visa_name,
            price: ml.price,
            map_id: ml.id,
          };
        }
        return null; // or any other value you prefer for undefined cases
      })
      .filter(Boolean); // filter out undefined values from the array
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const tableData = await table();
        const countryInfoData = await table2();
        const map_info = await table3();
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    const partnerfullinfo = getAllName();
    setMainList(partnerfullinfo);
    console.log("combined array");
    console.log(partnerfullinfo);
  }, [mapList]);

  const handleSaveVisaCategory = () => {
    axios
      .post(
        url + "visafees/createvisafees",
        {
          visa_id: state.visa_id,
          feetype_id: state.feetype_id,
          price: state.price,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        table3();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      visa_name: "",
      fee_name: "",
      feetype_id: "",
      visa_id: "",
      price: "",
      isUpdate: false,
    });
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        url + "visafees/deletevisafees",
        {
          id: idx,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table3())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        url + "visafees/updatevisafees",
        {
          id: idx,
          visa_id: state.visa_id,
          feetype_id: state.feetype_id,
          price: state.price,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        table3();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      visa_name: "",
      fee_name: "",
      feetype_id: "",
      visa_id: "",
      price: "",
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            Visa fee
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaList.map((item) => item.visa_name)} // Set options from entry type names
                  value={state.visa_name}
                  label="Visa for"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = visaList.find(
                      (item) => item.visa_name === v
                    );
                    setState((prevState) => ({
                      ...prevState,
                      visa_name: v,
                      visa_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                  // Handle onChange event
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  label="Fee Amount"
                  fullWidth
                  value={state.price}
                  onChange={(e) =>
                    setState({
                      ...state,
                      price: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={feelist.map((item) => item.fee_name)} // Set options from entry type names
                  value={state.fee_name}
                  label="Fee Types"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = feelist.find(
                      (item) => item.fee_name === v
                    );
                    setState((prevState) => ({
                      ...prevState,
                      fee_name: v,
                      feetype_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                  // Handle onChange event
                />
              </Grid>

              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateVisaCategory(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveVisaCategory();
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          <p className={classes.title}>Visa fee Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Visa", "Fee", "Price", "Action"]}
              rows={[
                ...mainlist
                  .filter(
                    (l) =>
                      l.visa_name
                        .toLowerCase()
                        .includes(state.searchText.toLowerCase()) ||
                      l.fee_name
                        .toLowerCase()
                        .includes(state.searchText.toLowerCase()) ||
                      l.price
                        .toLowerCase()
                        .includes(state.searchText.toLowerCase()) ||
                      state.searchText.toLowerCase() === undefined
                  )
                  .map((pl, idx) => [
                    idx + 1,
                    pl.visa_name,
                    pl.fee_name,
                    pl.price,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(pl.map_id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            visa_id: pl.visa_id,
                            visa_name: pl.visa_name,
                            feetype_id: pl.feetype_id,
                            fee_name: pl.fee_name,
                            price: pl.price,
                            isUpdate: true,
                            id: pl.map_id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
