import { Avatar, Divider, Typography, Button, Grid } from "@mui/material";
import React from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import CustomInputField from "../../components/CustomInputField/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import { useNavigate } from "react-router";
import PageTable from "../../components/PageTable/Index";
import MenuButton from "../../components/MenuButton/Index";
import url from "../../API";
import ImageWithPopup from "../../components/ImagewithPop/Index";
import DashboardHeader from "../../components/DashHeader/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
export default function InfoListPage() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");
  const [totalPages, setTotalPages] = React.useState(null);
  const [recordsTotal, setTotalrecord] = React.useState(null);

  const handleTable = async (newPage) => {
    // Update the page state first
    setPage(newPage);
  };

  const table = async (pg) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify({ page: pg }),
      };
      const res = await fetch(
        url + "visainformation/getallvisainformations",
        requestOptions
      );
      const actualData = await res.json();
      setList(actualData.Visa_Information);
      // setList([...list, actualData.Visa_Information]);
      // setTotalPages(actualData.total_pages);
      setTotalrecord(actualData.recordsTotal);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(list);
  React.useEffect(() => {
    table(page);
    if (localStorage.getItem("createVisa")) {
      toast.success("Visa Information created successfully", {
        position: "top-center",
      });
      localStorage.removeItem("createVisa");
    }
    if(localStorage.getItem("updateVisa")){
      toast.success("Visa Information updated successfully", {
        position: "top-center",
      });
      localStorage.removeItem("updateVisa");
    }
  }, [page]); // Fetch data whenever the page changes

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>
              Visa Information List
            </p>
          </div>
        </div>

        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/information_form");
              }}
            >
              Create Visa Information
            </Button>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <PageTable
            fullWidth
            cols={[
              "Sl No.",
              "Visa Name",
              "Visa Thumbnail",
              "Visa Pricing",
              "From Country",
              "Creation Date",
              "Receive within days",
              
              "Validity Period",
              "Action",
            ]}
            rows={[
              ...list
                .sort()
                .filter((l) => {
                  const visa_name = l.visa_name?.toLowerCase() || "";
                  const pricing = l.visa_pricing?.toLowerCase() || "";
                  const receive_days = l.receive_days?.toLowerCase() || "";
                  const from_country =
                    l.from_country_name?.toLowerCase() || "";
                  const validity_period =
                    l.validity_period?.toLowerCase() || "";
                  const searchLower = searchText.toLowerCase();
                  return (
                    visa_name.includes(searchLower) ||
                    from_country.includes(searchLower) ||
                    pricing.includes(searchLower) ||
                    receive_days.includes(searchLower) ||
                    validity_period.includes(searchText) ||
                    searchLower === undefined
                  );
                })
                .map((vs, idx) => [
                  (page - 1) * 8 + (idx + 1),
                  vs.visa_name,
                  // <Avatar
                  //   variant="rounded"
                  //   alt="VISA"
                  //   src={vs.visa_thumbnail}
                  //   style={{ margin: "auto" }}
                  // />,
                  <ImageWithPopup src={vs.visa_thumbnail} alt="Image" />,
                 
                  vs.visa_pricing,
                  vs.from_country_name,
                  vs.created_date.slice(0, 10),
                  vs.receive_days,
                
                  vs.validity_period,
                  <span className={classes.actionBtn}>
                    <MenuButton menuinfo={vs} table={table} />
                  </span>,
                ]),
            ]}
            page={page}
            setPage={handleTable} // Pass the handleTable function directly
            totalPages={totalPages}
            table={table}
            totalrecords={recordsTotal}
          />
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
