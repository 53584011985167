import { Autocomplete } from "@mui/material";
import React from "react";
import CustomInputField from "../CustomInputField/Index";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: "#787878" + "!important",
      color: "white",
    },
  },
}));

export default function CustomInputAndSelectField({
  freeSolo,
  options,
  label,
  required,
  textProps,
  error,
  helperText,
  changeCallBack,
  getOptionLabel,
  span,
  name,
  multiple = false, // Add this prop to control single or multiple selection
  ...props
}) {
  const classes = useStyles();

  return (
    <Autocomplete
      {...props}
      freeSolo={freeSolo}
      multiple={multiple} // Pass the multiple prop
      value={props.value ? props.value : (multiple ? [] : "")}
      onChange={changeCallBack}
      options={options}
      getOptionLabel={getOptionLabel}
      classes={{
        option: classes.option,
      }}
      renderInput={(params) => (
        <CustomInputField
          {...params}
          name={name}
          required={required}
          label={label}
          span={span}
          error={error}
          helperText={helperText}
          {...textProps}
        />
      )}
    />
  );
}
