import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useMemo, useRef } from "react";
import { FaEdit, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import url from "../../API";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import DashboardHeader from "../../components/DashHeader/Index";
import JoditEditor from "jodit-react";
export default function FAQ_for_visa() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { visaId } = location.state || {};
  const token = localStorage.getItem("tkn");
  const [visa_name, setvisa_name] = React.useState([]);
  const [state, setState] = React.useState({
    question: "",
    searchText: "",
    visa_name: "",
    visa_id: visaId,
    answer: "",
    isUpdate: false,
    id: null,
  });
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
    }),
    []
  );
  const [visaList, setVisaList] = React.useState([]);
  const [visaidList, setVisaidList] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletefaq_visaId, setDeletefaq_visaId] = React.useState(null);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        visa_id: visaId,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(url + "visa/faqdetailsbyvisaid", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setVisaList(actualData.Question_Answer_details);
      setvisa_name(actualData.Visa_name);
      setState((prevState) => ({
        ...prevState,
        visa_name: actualData.Visa_name,
      }));
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    table();
  }, []);
  const handleDelete = (idx) => {
    setDeletefaq_visaId(idx);
    setConfirmDialogOpen(true);
  };
  const handleSaveVisaCategory = () => {
    const { question, answer } = state;

    if (!question.trim() || !answer) {
      toast.error("Question and Answer fields cannot be empty", {
        position: "top-center",
      });
      return;
    }

    axios
      .post(
        url + "faqforvisa/createfaqforvisa",
        {
          question: state.question,
          answer: state.answer,
          visa_id: visaId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Created Sucessfully", {
            position: "top-center",
          });
          window.scrollTo({ top: 800, behavior: "smooth" });
          table();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setState({
      question: "",
      answer: "",
      visa_id: "",
      visa_name: "",
      isUpdate: false,
      id: null,
    });
  };

  const handleVisaCategoryDelete = () => {
    axios
      .post(
        url + "faqforvisa/deletefaqforvisa",
        {
          id: deletefaq_visaId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Deleted Sucessfully", {
            position: "top-center",
          });
          table();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setConfirmDialogOpen(false);
  };
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  const handleUpdateVisaCategory = (idx) => {
    const { question, answer } = state;

    if (!question.trim() || !answer) {
      toast.error("Question and Answer fields cannot be empty");
      return;
    }

    axios
      .post(
        url + "faqforvisa/updatefaqforvisa",
        {
          id: idx,
          question: state.question,
          answer: state.answer,
          visa_id: visaId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Updated Sucessfully", {
            position: "top-center",
          });
          window.scrollTo({ top: 800, behavior: "smooth" });
          table();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setState({
      question: "",
      answer: "",
      visa_id: "",
      visa_name: "",
      isUpdate: false,
      id: null,
    });
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Frequently Asked Questions</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={12}>
                <CustomInputField
                  label="Question"
                  fullWidth
                  value={state.question}
                  onChange={(e) =>
                    setState({
                      ...state,
                      question: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                Answer
                
                <JoditEditor
                  ref={editor}
                  value={state.answer}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) =>
                    setState({
                      ...state,
                      answer: newContent,
                    })
                  } // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                  config={config}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaidList.map((item) => item.visa_name)}
                  value={state.visa_name}
                  label="Visa Names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = visaidList.find(
                      (item) => item.visa_name === v
                    );
                    setState((prevState) => ({
                      ...prevState,
                      visa_name: v,
                      visa_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={() => handleUpdateVisaCategory(state.id)}
                  />
                ) : (
                  <CustomButton
                    text={"Create"}
                    variant="contained"
                    fullWidth
                    onClick={() => handleSaveVisaCategory()}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}> FAQ List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Question", "Answer", "Visa", "Action"]}
              rows={[
                ...visaList
                  .filter((l) => {
                    const question = l.question.toLowerCase();
                    const answer = 
                      l.answer
                    .toLowerCase();
                    const searchLower = (state.searchText || "").toLowerCase();
                    return (
                      question.includes(searchLower) ||
                      answer.includes(searchLower)
                    );
                  })
                  .map((vs, idx) => [
                    idx + 1,
                    vs.question,
                    <div dangerouslySetInnerHTML={{ __html: vs.answer }} />,
                    visa_name,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={() => handleDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          setState({
                            question: vs.question,
                            answer: vs.answer,
                            visa_name: visa_name,
                            isUpdate: true,
                            id: vs.id,
                          });
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
