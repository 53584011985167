import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import React, { useMemo, useRef } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaUserFriends, FaTimes, FaEdit } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import offersStyles from "../../styles/pages/Offers/index.style";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { Editor } from "react-draft-wysiwyg";
import CustomDatePicker from "../../components/CustomDateField/Index";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CountryTable from "../../components/CountryTable/Index";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import JoditEditor from "jodit-react";
export default function Testimonial() {
  const navigate = useNavigate();
  const location = useLocation();
  const { visaId } = location.state || {};
  const classes = offersStyles();
  const token = localStorage.getItem("tkn");
  const [list, setList] = React.useState([]);
  const [visalist, setVisalist] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deleteTestimonialId, setDeleteTestimonialId] = React.useState(null);
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
    }),
    []
  );
  const [state, setState] = React.useState({
    name: "",
    image: "",
    description: "",
    date: "",
    location: "",
    title: "",
    from_country: "",
    from_id: "",
    to_id: "",
    to_country: "",
    visa_name: "",
    visa_id: "",
    rating: "",
  });
  function table() {
    axios
      .post(
        url + "visa/customertestimonialdetailsbyvisaid",
        {
          visa_id: visaId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the response
        setList(response.data.Customer_Testimonials);
        setState((prevState) => ({
          ...prevState,
          visa_id: visaId,
          visa_name: response.data.Visa_name,
        }));
        // console.log(response.data.Country);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  const handleDeletePop = (idx) => {
    setDeleteTestimonialId(idx);
    setConfirmDialogOpen(true);
  };
  

  //http://13.232.52.101/api/visainformation/getallvisainformation
  const handleFromCountryChange = (v) => {
    const selectedEntryTypeName = v;

    const selectedEntryType = list.find(
      (item) => item.country_name === selectedEntryTypeName
    );

    setState((prevState) => ({
      ...prevState,
      from_country: v,
      from_id: selectedEntryType ? selectedEntryType.id : "",
    }));
    console.log(state);
  };
  const handleToCountryChange = (v) => {
    const selectedEntryTypeName = v;

    const selectedEntryType = list.find(
      (item) => item.country_name === selectedEntryTypeName
    );

    setState((prevState) => ({
      ...prevState,
      to_country: v,
      to_id: selectedEntryType ? selectedEntryType.id : "",
    }));
    console.log(state);
  };

  React.useEffect(() => {
    table();
  }, []);
  // React.useEffect(() => {
  //   if (localStorage.getItem("updateTestimonial")) {

  //   }
  // }, []);

  const handleSaveOfficer = async () => {
    try {
      // Input validation
      if (
        !state.name ||
        !state.image ||
        !state.description||
        !state.location ||
        !state.title ||
        !state.date ||
        !visaId ||
        !state.rating
      ) {
        toast.error("Please fill in all required fields.");
        return;
      }

      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = {
        customer_name: state.name,
        customer_image: state.image,
        description: state.description,
        customer_location: state.location,
        title: state.title,
        date: state.date,
        visa_id: visaId,
        rating: state.rating,
      };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "customertestimonial/createcustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);

      if (actualData.status === 200) {
        toast.success("Created Sucessfully", {
          position: "top-center",
        });
        table();
        setState((prevState) => ({
          ...prevState,
          name: "",
          image: "",
          description: "",
          date: "",
          location: "",
          title: "",
          rating: "",
        }));

        // Uncomment and modify this part if you want to use axios for the second API call
        /*
      axios
        .post(
          url + "customertestimonialmap/createcustomertestimonialmap",
          {
            from_country_id: state.from_id,
            to_country_id: state.to_id,
            testimonial_id: actualData.new_customer_testimonial_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((r) => {
          setState({
            name: "",
            image: "",
            description: EditorState.createEmpty(),
            date: "",
            location: "",
            title: "",
            from_country: "",
            from_id: "",
            to_id: "",
            to_country: "",
            visa_id: "",
            visa_name: "",
            rating: "",
          });
          localStorage.removeItem("customer");
          navigate("/testimonial_list");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      */
      } else {
        toast.error("Failed to save the officer. Please try again.");
      }
    } catch (err) {
      console.log(err);
      toast.error(
        "An error occurred while saving the officer. Please try again."
      );
    }
  };
  const handleUpdateOffice = async (id) => {
    try {
      // Validate data
      if (
        !state.name ||
        !state.image ||
        !state.location ||
        !state.title ||
        !state.date ||
        !visaId ||
        !state.rating
      ) {
        toast.error("Please fill in all required fields.");
        return;
      }

      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const data = {
        customer_name: state.name,
        customer_image: state.image,
        description: state.description,
        customer_location: state.location,
        title: state.title,
        date: state.date,
        id: id,
        visa_id: visaId,
        rating: state.rating,
      };

      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };

      const res = await fetch(
        url + "customertestimonial/updatecustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);

      if (actualData.status === 200) {
        toast.success("Updated Sucessfully", {
          position: "top-center",
        });
        table();
        setState((prevState) => ({
          ...prevState,
          name: "",
          image: "",
          description: "",
          date: "",
          location: "",
          title: "",
          isUpdate: false,
          map_id: "",
          rating: "",
        }));
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the testimonial.");
    }
  };
  const handleDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: deleteTestimonialId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        url + "customertestimonial/deletecustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
        setConfirmDialogOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Customer Testimonial
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={6}>
              <CustomInputField
                fullWidth
                label="Customer Name"
                value={state.name}
                // onChange={(e) => {
                //   setState({
                //     ...state,
                //     name: e.target.value,
                //   });
                // }}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-alphabetic characters
                  const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                  // Ensure input does not exceed 50 characters
                  const trimmedInput = validInput.slice(0, 50);
                  setState({
                    ...state,
                    name: trimmedInput,
                  });
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <CustomInputField
                type="date"
                label="Date"
                fullWidth
                value={state.date}
                onChange={(e) =>
                  setState({
                    ...state,
                    date: e.target.value,
                  })
                }
              />
            </Grid> */}
            <Grid item xs={6}>
             
              <p>Date</p>
              <input
                required=""
                style={{
                  padding: "10px",
                  // borderColor: "rgba(148,158,171,0.32)",
                  boxShadow: "1px 3px 10px rgba(0,0,0,0.17)",
                  width: "100%",
                  borderRadius: "10px",
                  border: "none",
                  marginTop: "20px",
                }}
                type="date"
                value={state.date}
                onChange={(e) =>
                  setState({
                    ...state,
                    date: e.target.value,
                  })
                }
                max={new Date().toISOString().split("T")[0]}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                fullWidth
                label=" Testimonial Title"
                value={state.title}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-alphabetic characters
                  
                  const trimmedInput =input.slice(0, 50);
                  setState({
                    ...state,
                    title: trimmedInput,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputField
                fullWidth
                label="Location"
                value={state.location}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-alphabetic characters
                  const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                  // Ensure input does not exceed 50 characters
                  const trimmedInput = validInput.slice(0, 50);
                  setState({
                    ...state,
                    location: trimmedInput,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputField
                fullWidth
                label="Customer Photo"
                type="file"
                onChange={(e) => {
                  const imageRef = ref(storage, `/customers+${Math.random()}`);
                  uploadBytes(imageRef, e.target.files[0]).then(() => {
                    getDownloadURL(imageRef)
                      .then((url) => {
                        // link = url;
                        //console.log("Image URL:", url);

                        // localStorage.setItem("customer", url);
                        console.log("variable value " + state.image);
                        // handleSaveVisaCategory();
                        setState({
                          ...state,
                          image: url,
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              {state.image && (
                <>
                  <p> image</p>
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={state.image}
                    style={{
                      margin: "auto",
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <CustomInputField
                fullWidth
                label="Rating"
                value={state.rating}
                onChange={(e) => {
                  const input = e.target.value;
                  // Remove non-digit characters
                  const numericInput = input.replace(/[^0-9]/g, "");
                  // Ensure input is within the range 1-5
                  const validInput = numericInput > 5 ? 5 : numericInput;

                  setState({
                    ...state,
                    rating: validInput,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputAndSelectField
                options={visalist.map((item) => item.visa_name)} // Set options from entry type names
                value={state.visa_name}
                label="Visa Name"
                fullWidth
                changeCallBack={(e, v) => {
                  const selectedEntryType = visalist.find(
                    (item) => item.visa_name === v
                  );
                  setState((prevState) => ({
                    ...prevState,
                    visa_name: v,
                    visa_id: selectedEntryType ? selectedEntryType.id : "",
                  }));
                }}
                // Handle onChange event
              />
            </Grid>
            <Grid item xs={12}>
              Description
              {/* <Editor
                label="Description"
                editorState={state.description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                // value={state.country_description}
                onEditorStateChange={(e) =>
                  setState({
                    ...state,
                    description: e,
                  })
                }
              /> */}
              <JoditEditor
                  ref={editor}
                  value={state.description}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) =>
                    setState({
                      ...state,
                      description: newContent,
                    })
                  } // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                  config={config}
                />
            </Grid>
            <Grid item xs={12}>
              {state.isUpdate ? (
                <Button
                  variant="contained"
                  fullWidth
                  color="warning"
                  onClick={(e) => {
                    handleUpdateOffice(state.id);
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    handleSaveOfficer();
                  }}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
          <ToastContainer />
          <p className={classes.title}>Testimonial Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="searchField"
            />
            <CountryTable
              fullWidth
              cols={[
                "Sl No.",
                "Customer Name",
                "Customer Image",
                "Description",
                "Title",
                "Location",
                "Date",
                "Rating",
                "Action",
              ]}
              rows={[
                ...list
                  .filter(
                    (l) =>
                      l.customer_name
                        .toLowerCase()
                        .includes((searchText || "").toLowerCase()) ||
                      l.customer_location
                        .toLowerCase()
                        .includes((searchText || "").toLowerCase()) ||
                      l.date
                        .toLowerCase()
                        .includes((searchText || "").toLowerCase()) ||
                      l.customer_location
                        .toLowerCase()
                        .includes((searchText || "").toLowerCase()) ||
                      l.rating
                        .toLowerCase()
                        .includes((searchText || "").toLowerCase()) ||
                      l.title
                        .toLowerCase()
                        .includes((searchText || "").toLowerCase()) ||
                      (searchText || "").toLowerCase() === undefined
                  )
                  .map((vs, idx) => [
                    idx + 1,
                    vs.customer_name,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={vs.customer_image}
                      style={{
                        margin: "auto",
                      }}
                    />,
                    vs.description.length > 25
                      ? vs.description.slice(0, 25) +
                        "...."
                      : vs.description,
                    vs.title,
                    vs.customer_location,
                    vs.date,
                    // vs.visa_name,
                    vs.rating,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleDeletePop(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState({
                            name: vs.customer_name,
                            image: vs.customer_image,
                            // description: EditorState.createEmpty(),
                            description: vs.description,
                            date: vs.date,
                            location: vs.customer_location,
                            title: vs.title,
                            visa_id: visaId,
                            rating: vs.rating,
                            isUpdate: true,
                            id: vs.id,
                          });
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                          // localStorage.setItem(
                          //   "updateTestimonial",
                          //   JSON.stringify(vs.visaInfo)
                          // );
                          // localStorage.setItem(
                          //   "updateTestMap",
                          //   JSON.stringify(vs)
                          // );
                          // localStorage.setItem("testimonialMapId", vs.map_id);
                          // localStorage.setItem("entry_id", vs.entry_id);
                          // navigate("/testimonial");
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleDelete}
      />
    </div>
  );
}
