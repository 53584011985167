import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useMemo, useRef } from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import JoditEditor from "jodit-react";
export default function FAQ() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    question: "",
    searchText: "",
    answer: "",
    isUpdate: false,
  });
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
    }),
    []
  );
  const [visaList, setVisaList] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletefaqId, setDeletefaqId] = React.useState(null);
  function table() {
    axios
      .get(url + "FAQS/GetAllFrequently_Ask_Question", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setVisaList(response.data.FAQ);
        console.log(response.data.FAQ);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }
  React.useEffect(() => {
    table();
  }, []);

  const handleDelete = (idx) => {
    setDeletefaqId(idx);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  const handleSaveVisaCategory = () => {
    axios
      .post(
        url + "FAQS/createFrequently_Ask_Question",
        {
          question: state.question,
          answer: state.answer,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Created Sucessfully", {
            position: "top-center",
          });
          table();
          window.scrollTo({ top: 500, behavior: "smooth" });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      question: "",
      answer: "",
    });
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        url + "FAQS/deleteFrequently_Ask_Question",
        {
          id: deletefaqId,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Deleted Sucessfully", {
            position: "top-center",
          });
          setConfirmDialogOpen(false);
          table();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        url + "FAQS/updateFrequently_Ask_Question",
        {
          id: idx,
          question: state.question,
          answer: state.answer,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        if (r.data.status == 200) {
          toast.success("Updated Sucessfully", {
            position: "top-center",
          });
          table();
          window.scrollTo({ top: 500, behavior: "smooth" });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      question: "",
      answer: "",
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>FAQ</p>
          </div>
        </div>
        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          {/* <p className={classes.title}>Frequently Asked Questions</p> */}
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={12}>
                <CustomInputField
                  label="Question"
                  fullWidth
                  value={state.question}
                  onChange={(e) =>
                    setState({
                      ...state,
                      question: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                Answer
                <JoditEditor
                  ref={editor}
                  value={state.answer}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) =>
                    setState({
                      ...state,
                      answer: newContent,
                    })
                  } // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                  config={config}
                />
                
              </Grid>
             
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.question &&
                        !!state.answer
                      ) {
                        handleUpdateVisaCategory(state.id);
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (
                        !!state.question &&
                        !!state.answer
                      ) {
                        handleSaveVisaCategory();
                      } else {
                        toast.error("Fill all the information", {
                          position: "top-center",
                        });
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <ToastContainer />
          </Paper>
          <p className={classes.title}>FAQ List</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Question", "Answer", "Action"]}
              rows={[
                ...visaList
                  .sort()
                  .filter((l) => {
                    const question = l.question?.toLowerCase() || "";
                    const answer =
                      l.answer?.toLowerCase() || "";
                    const searchLower = (state.searchText || "").toLowerCase();
                    return (
                      question.includes(searchLower) ||
                      answer.includes(searchLower) ||
                      searchLower === undefined
                    );
                  })
                  // .filter(
                  //   (l) =>
                  //     l.question
                  //       .toLowerCase()
                  //       .includes(state.searchText.toLowerCase()) ||
                  //     JSON.parse(l.answer)
                  //       .blocks[0].text.toLowerCase()
                  //       .includes(state.searchText.toLowerCase()) ||
                  //     state.searchText.toLowerCase() === undefined
                  // )
                  .map((vs, idx) => [
                    idx + 1,
                    vs.question,
                   
                    <div dangerouslySetInnerHTML={{ __html: vs.answer }} />,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) => {
                          setState({
                            question: vs.question,
                            answer: vs.answer,
                            isUpdate: true,
                            id: vs.id,
                          });
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
    </div>
  );
}
