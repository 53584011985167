import { Avatar, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useMemo, useRef } from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CountryTable from "../../components/CountryTable/Index";
import slugify from "react-slugify";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import classNames from "classnames";
import ImageWithPopup from "../../components/ImagewithPop/Index";
import DashboardHeader from "../../components/DashHeader/Index";
import JoditEditor from "jodit-react";
export default function CountryPage() {
  const navigate = useNavigate();
  const classes = offerStyles();
  const formData = new FormData();
  const location = useLocation();
  const { updateId } = location.state || {};
  const [state, setState] = React.useState({
    country_name: "",
    country_banner: "",
    country_flag: "",
    country_description: "",
    currency: "",
    currency_code: "",
    isUpdate: false,
    is_citizen_available: false,
  });
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
    }),
    []
  );
  const [visaList, setVisaList] = React.useState([]);
  React.useEffect(() => {
    // table();
    if (updateId) {
      const updateobj = updateId;
      // console.log(updateobj.id);
      setState({
        country_name: updateobj.country_name,
        country_banner: updateobj.country_banner,
        country_flag: updateobj.country_flag,
        country_description: updateobj.country_description,
        currency: updateobj.currency,
        currency_code: updateobj.currency_code,
        isUpdate: true,
        visaId: updateobj.id,
        is_citizen_available: updateId.is_citizenship_available || false,
      });
      // localStorage.removeItem("updateId");
      // handleUpdateVisaCategory(updateobj.id);
    }
  }, []);

  const handleSaveVisaCategory = async () => {
    if (
      state.country_name == "" ||
      state.currency_code == "" ||
      state.country_banner == "" ||
      state.country_flag == "" ||
      state.currency == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      try {
        const loginHeaders = new Headers();
        loginHeaders.append("Content-Type", "application/json");

        // Assuming you have an authorization token stored in localStorage
        const authToken = localStorage.getItem("tkn");
        if (authToken) {
          loginHeaders.append("Authorization", `Bearer ${authToken}`);
        }
        const data = {
          country_name: state.country_name,
          country_banner: state.country_banner,
          country_flag: state.country_flag,
          country_description: state.country_description.getCurrentContent,
          currency: state.currency,
          currency_code: state.currency_code,
          is_citizenship_available:state.is_citizen_available
        };
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(url + "country/createcountry", requestOptions);
        const actualData = await res.json();
        console.log(actualData);
        // setVisaList(actualData.Country);
        if (actualData.status == 200) {
          // table();
          setState({
            country_name: "",
            country_banner: "",
            country_flag: "",
            country_description: "",
            currency: "",
            currency_code: "",
            is_citizen_available:false,
            isUpdate: false,
          });
          navigate("/country_list2");
          localStorage.setItem("createCountry", 1);
        } else {
          toast.error(actualData.message, {
            position: "top-center",
          });
        }
      } catch (err) {
        toast.error("An error occured", {
          position: "top-center",
        });
        console.log(err);
      }
    }
  };
  const handleUpdateVisaCategory = async (id) => {
    if (
      state.country_name == "" ||
      state.country_description == "" ||
      state.currency_code == "" ||
      state.country_banner == "" ||
      state.country_flag == "" ||
      state.currency == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      try {
        const loginHeaders = new Headers();
        loginHeaders.append("Content-Type", "application/json");

        // Assuming you have an authorization token stored in localStorage
        const authToken = localStorage.getItem("tkn");
        if (authToken) {
          loginHeaders.append("Authorization", `Bearer ${authToken}`);
        }
        const data = {
          country_name: state.country_name,
          country_banner: state.country_banner,
          country_flag: state.country_flag,
          country_description: state.country_description.getCurrentContent,
          currency: state.currency,
          currency_code: state.currency_code,
          country_slug: slugify(state.country_name),
          is_citizenship_available:state.is_citizen_available,
          id: id,
        };
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(url + "country/updatecountry", requestOptions);
        const actualData = await res.json();
        console.log(actualData);
        if (actualData.status == 200) {
          localStorage.removeItem("updateId");
          toast.success("Updated Sucessfully", {
            position: "top-center",
          });
          setState({
            country_name: "",
            country_banner: "",
            country_flag: "",
            country_description: "",
            currency: "",
            currency_code: "",
            is_citizen_available:false,
            isUpdate: false,
          });

          navigate("/country_list2");
          localStorage.setItem("updateCountry", 1);
        } else {
          toast.error(actualData.message, {
            position: "top-center",
          });
        }
      } catch (err) {
        toast.error("An error occured", {
          position: "top-center",
        });
        console.log(err);
      }
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />

        <Divider className={classes.divider} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {" "}
          {/* <Typography variant="h5" color={"primary"}>
              <AiFillHome /> DashBoard
            </Typography> */}
          <p style={{ marginTop: "2px", fontSize: "22px" }}>Country</p>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={6}>
                <CustomInputField
                  label="Country Name"
                  fullWidth
                  value={state.country_name}
                  onChange={(e) => {
                    const input = e.target.value;
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    setState({
                      ...state,
                      country_name: validInput,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <CustomInputField
                  label="Country Banner"
                  type="File"
                  fullWidth
                  onChange={(e) => {
                    const imageRef = ref(storage, `/images+${Math.random()}`);
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          // localStorage.setItem("banner", url);
                          console.log("variable value " + url);
                          setState({
                            ...state,
                            country_banner: url,
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {state.country_banner && (
                  <>
                    <p>Banner image</p>{" "}
                    {/* <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.country_banner}
                      style={{
                        margin: "auto",
                      }}
                    /> */}
                    <ImageWithPopup
                      src={state.country_banner}
                      alt="Example Image"
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <CustomInputField
                  label="Country Flag"
                  type="File"
                  fullWidth
                  onChange={(e) => {
                    const imageRef = ref(storage, `/images+${Math.random()}`);
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          // localStorage.setItem("flag", url);
                          // console.log("variable value " + state.image);
                          setState({
                            ...state,
                            country_flag: url,
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {state.country_flag && (
                  <>
                    <p>Flag image</p>{" "}
                    {/* <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.country_flag}
                      style={{
                        margin: "auto",
                      }}
                    /> */}
                    <ImageWithPopup
                      src={state.country_flag}
                      alt="Example Image"
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                <CustomInputField
                  label="Currency"
                  fullWidth
                  value={state.currency}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove non-alphabetic characters
                    const validInput = input.replace(/[^a-zA-Z\s]/g, "");
                    // Ensure input does not exceed 50 characters
                    const trimmedInput = validInput.slice(0, 50);
                    setState({
                      ...state,
                      currency: trimmedInput,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <CustomInputField
                  label="Currency Symbol"
                  fullWidth
                  value={state.currency_code}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Remove any numeric characters
                    const validInput = input.replace(/[0-9]/g, "");
                    // Limit the input to 10 characters
                    const limitedInput = validInput.slice(0, 10);
                    setState({
                      ...state,
                      currency_code: limitedInput,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <span
                        style={{
                          marginRight: "1rem",
                          fontWeight: "500",
                          color: "#27282C",
                          fontSize: "1rem", // Slightly larger font size for better readability
                        }}
                      >
                        Is Citizenship Available
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",  
                        
                        gap: "0.5rem",
                      }}
                    >
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          value="yes"
                          name="citizenship"
                          checked={state.is_citizen_available} // Check if true
                          onChange={() =>
                            setState((prevState) => ({
                              ...prevState,
                              is_citizen_available: true, // Set to true for Yes
                            }))
                          }
                          style={{ marginRight: "8px", }} // Custom accent color for radio button
                        />
                        Yes
                      </label>

                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          value="no"
                          name="citizenship"
                          checked={!state.is_citizen_available} // Check if false
                          onChange={() =>
                            setState((prevState) => ({
                              ...prevState,
                              is_citizen_available: false, // Set to false for No
                            }))
                          }
                          style={{ marginRight: "8px", }} // Custom accent color for radio button
                        />
                        No
                      </label>
                    </div>
                 
              </Grid>
              <Grid item xs={12}>
                <p>Description</p>
                {/* <Editor
                  label="Description"
                  editorState={state.country_description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) =>
                    setState({
                      ...state,
                      country_description: e,
                    })
                  }
                /> */}
                <JoditEditor
                  ref={editor}
                  value={state.country_description}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) =>
                    setState({
                      ...state,
                      country_description: newContent,
                    })
                  } // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                  config={config}
                />
              </Grid>
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateVisaCategory(state.visaId);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"Create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveVisaCategory();
                    }}
                  />
                )}
              </Grid>
              <ToastContainer />
            </Grid>
          </Paper>
          {/* <p className={classes.title}>Visa Lists</p>
          <Paper className={classes.paper}> */}
          {/* <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            /> */}
          {/* <CountryTable
              fullWidth
              cols={[
                "Sl No.",
                "Country Name",
                "Banner",
                "Flag",
                "Description",
                "Currency",
                "Currency Code",
                "Action",
              ]}
              rows={[
                ...visaList
                  .sort()

                  .map((vs, idx) => [
                    idx + 1,
                    vs.country_name,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={vs.country_banner}
                      style={{
                        margin: "auto",
                      }}
                    />,
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={vs.country_flag}
                      style={{
                        margin: "auto",
                      }}
                    />,
                    vs.country_description,
                    vs.currency,
                    vs.currency_code,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(vs.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            country_name: vs.country_name,
                            country_banner: vs.country_banner,
                            country_flag: vs.country_flag,
                            country_description: vs.country_description,
                            currency: vs.currency,
                            currency_code: vs.currency_code,
                            isUpdate: true,
                            visaId: vs.id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            /> */}
          {/* </Paper> */}
        </div>
      </div>
    </div>
  );
}
