// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAbXwdPANXBZc5XuajWSW6La9EyjvhAL3I",
  authDomain: "visaroot-fe3d2.firebaseapp.com",
  projectId: "visaroot-fe3d2",
  storageBucket: "visaroot-fe3d2.appspot.com",
  messagingSenderId: "452570314289",
  appId: "1:452570314289:web:5c65d090ee86f63cc14710",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
