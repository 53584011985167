import { CircularProgress } from "@mui/material";
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import "./App.css";
import DashBoard from "./pages/Dashboard/Index";
import Login from "./pages/Login/Login";
import useStyles from "./styles/main.styles";
import GlobalStyles from "./theme/globalStyles";
import ThemeConfig from "./theme/index";
import Coupon from "./pages/Dashboard/coupon";
import PayoutPage from "./pages/Dashboard/payout";
import VisaTimePage from "./pages/VisaTime/Index";
import CountryPage from "./pages/Country/Index";
import CountryList2Page from "./pages/CountryList_2/Index";
import InformationPage from "./pages/Information_form/Index";
import InfoListPage from "./pages/Information_list/Index";
import EntryPage from "./pages/Entry/Index";
import Immigration from "./pages/Immigration_form";
import ImmigrationList from "./pages/Immigration_list/Index";
import Partener_toWork from "./pages/Partner_to_work/Index";
import FAQ_for_visa from "./pages/FAQ_for_visa/Index";
import Testimonial from "./pages/Testimonial";
import Testimonial_list from "./pages/Testimonial_list/Index";
import Discount_type from "./pages/Discount_type/Index";
import QuestionPage from "./pages/Question/Index";
import Visa_for_country from "./pages/For_which_country/Index";
import Payment_gateway from "./pages/Payment_gateway/Index";
import FAQ_for_country from "./pages/FAQ_for_country";
import VisaApplicationStatus from "./pages/Visa_application_status/Index";
import Docs_required from "./pages/Docs_required/Index";
import Question_list from "./pages/Question_list/Index";
import FAQ from "./pages/FAQ/Index";
import Fee_type from "./pages/Fee_type/Index";
import Visa_fee from "./pages/Visa_Fee/Index";
import News_letter from "./pages/News_letter/Index";
import VisaComparePricing from "./pages/VisaComparePricing/Index";
import Application_list from "./pages/Application_list/Index";
import CouponAdd from "./pages/Coupon/Index";
import Seo from "./pages/SEO/Index";
import Application_details from "./pages/Application_details/Index";
import BlogSection from "./pages/BlogSection/Index";
import BlogCategory from "./pages/Blog_category/Index";
import Bloglist from "./pages/Blog_list/Index";
import CoverDetails from "./pages/Cover_details/Index";
import Customer_testimonial from "./pages/Customer_testimonial/Index";
import Customer_testimonialList from "./pages/Customer_testimonialList/Index";
import Customize_page from "./pages/Customize_page/Index";
import Settings from "./pages/Settings/Index";
import User_Contact_List from "./pages/User_Contact_List/Index";
import User_List from "./pages/User_list/Index";
import Send_NewsLtr from "./pages/Send_NewsLtr/Index";
import Mail_details from "./pages/Mail_details/Index";
import CareersPage from "./pages/CareesPage/Index";
import InvestorList from "./pages/InvestorList";
import Applicable_coupon from "./pages/Applicable_Coupon/Index";

const App = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const token = localStorage.getItem("tkn");

  React.useEffect(() => {
    setLoading(false);
  }, []);

  let routes = useRoutes([
    {
      path: "/",
      element: !token ? <Login /> : <Navigate to="/dashboard" />,
    },
    {
      path: "/dashboard",
      element: token ? <DashBoard /> : <Navigate to="/" />,
    },
    {
      path: "/country",
      element: token ? <CountryPage /> : <Navigate to="/" />,
    },
    {
      path: "/information_form",
      element: token ? <InformationPage /> : <Navigate to="/" />,
    },
    {
      path: "/country_list2",
      element: token ? <CountryList2Page /> : <Navigate to="/" />,
    },
    {
      path: "/information_list",
      element: token ? <InfoListPage /> : <Navigate to="/" />,
    },
    {
      path: "/entry",
      element: token ? <EntryPage /> : <Navigate to="/" />,
    },
    {
      path: "/immigration_form",
      element: token ? <Immigration /> : <Navigate to="/" />,
    },
    {
      path: "/immigration_list",
      element: token ? <ImmigrationList /> : <Navigate to="/" />,
    },
    {
      path: "/visa_time",
      element: token ? <VisaTimePage /> : <Navigate to="/" />,
    },
    {
      path: "/partners",
      element: token ? <Partener_toWork /> : <Navigate to="/" />,
    },
    {
      path: "/faq_visa",
      element: token ? <FAQ_for_visa /> : <Navigate to="/" />,
    },
    {
      path: "/testimonial",
      element: token ? <Testimonial /> : <Navigate to="/" />,
    },
    {
      path: "/testimonial_list",
      element: token ? <Testimonial_list /> : <Navigate to="/" />,
    },
    {
      path: "/discount",
      element: token ? <Discount_type /> : <Navigate to="/" />,
    },
    {
      path: "/question",
      element: token ? <QuestionPage /> : <Navigate to="/" />,
    },
    {
      path: "/for_which_country",
      element: token ? <Visa_for_country /> : <Navigate to="/" />,
    },
    {
      path: "/payment_gateway",
      element: token ? <Payment_gateway /> : <Navigate to="/" />,
    },
    {
      path: "/faq_for_country",
      element: token ? <FAQ_for_country /> : <Navigate to="/" />,
    },
    {
      path: "/application_status",
      element: token ? <VisaApplicationStatus /> : <Navigate to="/" />,
    },
    {
      path: "/docs_required",
      element: token ? <Docs_required /> : <Navigate to="/" />,
    },
    {
      path: "/question_list",
      element: token ? <Question_list /> : <Navigate to="/" />,
    },
    {
      path: "/faq",
      element: token ? <FAQ /> : <Navigate to="/" />,
    },
    {
      path: "/fee_type",
      element: token ? <Fee_type /> : <Navigate to="/" />,
    },
    {
      path: "/Visa_fee",
      element: token ? <Visa_fee /> : <Navigate to="/" />,
    },
    {
      path: "/coupon",
      element: token ? <CouponAdd /> : <Navigate to="/" />,
    },
    {
      path: "/pricing_cmp",
      element: token ? <VisaComparePricing /> : <Navigate to="/" />,
    },
    {
      path: "/news_letter",
      element: token ? <News_letter /> : <Navigate to="/" />,
    },
    {
      path: "/seo",
      element: token ? <Seo /> : <Navigate to="/" />,
    },
    {
      path: "/application_list",
      element: token ? <Application_list /> : <Navigate to="/" />,
    },
    {
      path: "/blog",
      element: token ? <BlogSection /> : <Navigate to="/" />,
    },
    {
      path: "/blog_category",
      element: token ? <BlogCategory /> : <Navigate to="/" />,
    },
    {
      path: "/bloglist",
      element: token ? <Bloglist /> : <Navigate to="/" />,
    },
    {
      path: "/cover_letter_list",
      element: token ? <CoverDetails /> : <Navigate to="/" />,
    },
    {
      path: "/application_details",
      element: token ? <Application_details /> : <Navigate to="/" />,
    },
    {
      path: "/dashboard/coupons",
      element: token ? <Coupon /> : <Navigate to="/" />,
    },
    {
      path: "/dashboard/payouts",
      element: token ? <PayoutPage /> : <Navigate to="/" />,
    },
    {
      path: "/customer_testimonial",
      element: token ? <Customer_testimonial /> : <Navigate to="/" />,
    },
    {
      path: "/customer_testimoniallist",
      element: token ? <Customer_testimonialList /> : <Navigate to="/" />,
    },
    {
      path: "/customize_page",
      element: token ? <Customize_page /> : <Navigate to="/" />,
    },
    {
      path: "/setting",
      element: token ? <Settings /> : <Navigate to="/" />,
    },
    {
      path: "/contact_list",
      element: token ? <User_Contact_List /> : <Navigate to="/" />,
    },
    {
      path: "/user_list",
      element: token ? <User_List /> : <Navigate to="/" />,
    },
    {
      path: "/send_mail",
      element: token ? <Send_NewsLtr /> : <Navigate to="/" />,
    },
    {
      path: "/mail_details",
      element: token ? <Mail_details /> : <Navigate to="/" />,
    },
    {
      path: "/careers",
      element: token ? <CareersPage /> : <Navigate to="/" />,
    },
    {
      path: "/investors",
      element: token ? <InvestorList /> : <Navigate to="/" />,
    },
    {
      path: "/applicable_coupon",
      element: token ? <Applicable_coupon /> : <Navigate to="/" />,
    },
    { path: "*", element: <Navigate to={"/404"} /> },
  ]);

  if (loading)
    return (
      <div className={classes.loader}>
        <CircularProgress
          disableShrink
          size={35}
          color="primary"
          thickness={6}
        />
      </div>
    );

  return <div className={classes.headContainer}>{routes}</div>;
};

const AppWrapper = () => {
  return (
    <div className="App">
      <ThemeConfig>
        <GlobalStyles />
        <App />
      </ThemeConfig>
    </div>
  );
};

export default AppWrapper;
