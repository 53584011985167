import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Switch,
} from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import { useNavigate } from "react-router";
import { MdLogout } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import url from "../../API";
import ConfirmationDialog from "../../components/ConfirmationDialog/Index";
import DashboardHeader from "../../components/DashHeader/Index";

export default function Payment_gateway() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    searchText: "",
    payment_gateway_name: "",
    isUpdate: false,
    id: null,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletegatewayId, setDeletegatewayId] = React.useState(null);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupData, setPopupData] = React.useState({
    id: null,
    payment_name: "",
    stripe_webhook_id: "",
    stripe_api_key: "",
    paypal_client_id: "",
    paypal_webhook_id: "",
    paypal_secrete_key: "",
    stripe_secrete_key: "",
    active: false,
  });

  function table() {
    axios
      .get(url + "paymentgateway/getallpaymentgateway", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response
        setVisaList(response.data.Payment_Get_Way);
        console.log(response.data.Payment_Get_Way);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  }

  React.useEffect(() => {
    table();
  }, []);

  const handleDelete = (idx) => {
    setDeletegatewayId(idx);
    setConfirmDialogOpen(true);
  };
  console.log(popupData);
  const handleSaveVisaCategory = () => {
    const { payment_gateway_name } = state;

    // Validation to check for non-empty fields
    if (!payment_gateway_name) {
      toast.error("Please fill in all required fields.");
      return;
    }
    axios
      .post(
        url + "paymentgateway/createpaymentgateway",
        {
          payment_gateway_name: state.payment_gateway_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r) => {
        toast.success("Created Successfully", {
          position: "top-center",
        });
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      payment_gateway_name: "",
    });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleVisaCategoryDelete = () => {
    axios
      .post(
        url + "paymentgateway/deletepaymentgateway",
        {
          id: deletegatewayId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r) => {
        toast.success("Deleted Successfully", {
          position: "top-center",
        });
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setConfirmDialogOpen(false);
  };

  const handleUpdateVisaCategory = (idx) => {
    const { payment_gateway_name } = state;

    // Validation to check for non-empty fields
    if (!payment_gateway_name) {
      toast.error("Please fill in all required fields.");
      return;
    }
    axios
      .post(
        url + "paymentgateway/updatepaymentgateway",
        {
          id: idx,
          payment_gateway_name: state.payment_gateway_name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r) => {
        toast.success("Updated Successfully", {
          position: "top-center",
        });
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      payment_gateway_name: "",
      isUpdate: false,
    });
  };

  const handlePopupOpen = (data) => {
    setPopupData(data);
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handlePopupChange = (field, value) => {
    setPopupData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handlePopupSubmit = () => {
    // Handle the submit action here
    console.log("Updated Data: ", popupData);

    // Validation to check for non-empty fields

    axios
      .post(
        url + "paymentgateway/updatepaymentgateway",
        {
          id: popupData.id,
          payment_name: popupData.payment_name,
          stripe_webhook_id: popupData.stripe_webhook_id,
          stripe_api_key: popupData.stripe_api_key,
          paypal_client_id: popupData.paypal_client_id,
          paypal_webhook_id: popupData.paypal_webhook_id,
          paypal_secrete_key: popupData.paypal_secrete_key,
          stripe_secrete_key: popupData.stripe_secrete_key,
          active: popupData.active ? 1 : 0,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r) => {
        toast.success("Updated Successfully", {
          position: "top-center",
        });

        // You can add the axios request to update the data here
        setPopupOpen(false);
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
console.log(popupData,"popupdata");

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <p style={{ marginTop: "2px", fontSize: "22px" }}>
              Payment Gateway Options
            </p>
          </div>
        </div>
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Payment Gateway", "Action"]}
              rows={[
                ...visaList
                  .filter((l) => {
                    const payment_name = l.payment_name?.toLowerCase() || "";
                    const searchLower = (state.searchText || "").toLowerCase();
                    return payment_name.includes(searchLower);
                  })
                  .map((vs, idx) => [
                    idx + 1,
                    vs.payment_name,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={() => handlePopupOpen(vs)}
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleVisaCategoryDelete}
      />
      <Dialog open={popupOpen} onClose={handlePopupClose}>
        <DialogTitle>Edit Payment Gateway</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomInputField
                label="Payment Gateway Name"
                fullWidth
                value={popupData.payment_name}
                onChange={(e) =>
                  handlePopupChange("payment_name", e.target.value)
                }
              />
            </Grid>
            { popupData.payment_name=="Paypal" && (
              <>
                <Grid item xs={12}>
                  <CustomInputField
                    label="PayPal Client ID"
                    fullWidth
                    value={popupData.paypal_client_id}
                    onChange={(e) =>
                      handlePopupChange("paypal_client_id", e.target.value)
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <CustomInputField
                label={
                  popupData.paypal_webhook_id  || popupData.payment_name=="Paypal"
                    ? "PayPal Webhook Id"
                    : "Stripe Webhook Id"
                }
                fullWidth
                value={
                  popupData.paypal_webhook_id || popupData.payment_name=="Paypal"
                    ? popupData.paypal_webhook_id
                    : popupData.stripe_webhook_id
                }
                onChange={(e) => {
                  if (popupData.paypal_webhook_id || popupData.payment_name=="Paypal") {
                    handlePopupChange("paypal_webhook_id", e.target.value);
                  } else {
                    handlePopupChange("stripe_webhook_id", e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputField
                label={
                  popupData.paypal_secrete_key || popupData.payment_name=="Paypal"
                    ? "PayPal Secret Key"
                    : "Stripe Secret Key"
                }
                fullWidth
                value={
                  popupData.paypal_secrete_key || popupData.payment_name=="Paypal"
                    ? popupData.paypal_secrete_key
                    : popupData.stripe_secrete_key
                }
                onChange={(e) => {
                  if (popupData.paypal_secrete_key || popupData.payment_name=="Paypal") {
                    handlePopupChange("paypal_secrete_key", e.target.value);
                  } else {
                    handlePopupChange("stripe_secrete_key", e.target.value);
                  }
                }}
              />
            </Grid>
            { popupData.payment_name=="Stripe" && (
              <>
                <Grid item xs={12}>
                  <CustomInputField
                    label="Stripe API Key"
                    fullWidth
                    value={popupData.stripe_api_key}
                    onChange={(e) =>
                      handlePopupChange("stripe_api_key", e.target.value)
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography>Active</Typography>
              <Switch
                checked={popupData.active}
                onChange={(e) => handlePopupChange("active", e.target.checked)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopupClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePopupSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
