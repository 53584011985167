import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import { MdLogout } from "react-icons/md";
import url from "../../API";
import DashboardHeader from "../../components/DashHeader/Index";
export default function InvestorList() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(url + "invester/getallinvester", requestOptions);
      const actualData = await res.json();
      console.log(actualData);
      setList(actualData.Invester);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
  }, []);
  // const handleDeleteCountry = async (idx) => {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const data = { id: idx };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       url +"blog/deleteblog",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     // setVisaList(actualData.Country);
  //     if (actualData.status == 200) {
  //       table();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <DashboardHeader />
        <Divider className={classes.divider} />
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {" "}
            <p style={{ marginTop: "2px", fontSize: "22px" }}>Investor List</p>
          </div>
        </div>

        {/* <Divider className={classes.divider} /> */}
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
                "Sl No.", 
                "Email", 
                "Phone", 
                "Occupation", 
                "Investment Amount", 
               
                "Net Worth", 
                "DOB", 
               
              ]}
            rows={[
                ...list
                  .sort((a, b) => a.id - b.id) // Sort by id or any other field
                  .filter((item) => {
                    const email = item.email?.toLowerCase() || "";
                    const phone = item.phone?.toString() || "";
                    const occupation = item.occupation?.toLowerCase() || "";
                    const searchLower = searchText.toLowerCase();
              
                    return (
                      email.includes(searchLower) ||
                      phone.includes(searchLower) ||
                      occupation.includes(searchLower) ||
                      searchLower === ""
                    );
                  })
                  .map((item, idx) => [
                    idx + 1,
                    item.email || "--",
                    item.phone || "--",
                    item.occupation || "--",
                    item.investmentAmount || "--",
                  
                    item.netWorth || "--",
                    item.dob || "--",
                  
                  ]),
              ]}
              
          />
        </div>
      </div>
    </div>
  );
}
